import { configureStore } from '@reduxjs/toolkit';

import authSlice from '../features/auth/authSlice';
import dashboardSlice from '../features/dashboard/dashboardSlice';
import layoutSlice from '../features/layout/layoutSlice';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		dashboard: dashboardSlice,
		layout: layoutSlice
	}
});