import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	login: false,
	token: false,
	usr: false
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		
		loginStatus: (state, action) => {
			state.login = action.payload;
		},
		
		updateToken: (state, action) => {
			state.token = action.payload
		},
		
		updateUsr: (state, action) => {
			state.usr = action.payload
		},
		
		unlogin: (state, action) => {
			state.login = false;
			state.token = false;
			state.usr = false;
		}
		
	}
});

export const { loginStatus, updateToken, unlogin, updateUsr } = authSlice.actions;
export default authSlice.reducer;