const ButtonSmall = ({ children, onClick, color }) => {
	color = color || '';
	
	return (
		<button className={`button-small ${color}`} onClick={onClick}>
			{children}
		</button>
	);
}

export default ButtonSmall;