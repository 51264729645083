import { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";

import Button from '../components/Button';

import { updateToken, loginStatus, updateUsr } from '../features/auth/authSlice';
import apiURL from '../hooks/apiURL';

import useInput from "../hooks/useInput";


import Logo from '../media/LOGO_Softuristic_b.png';


const LoginPage = () => {
	const dispatch = useDispatch();
	
	const [cookies, setCookie] = useCookies();
	
	const navigate = useNavigate();
	
	const params = useParams();
	
	const email = useInput('ivan@drowers.es');
	const password = useInput('');
	const [error, setError] = useState(false);
	
	useEffect(() => {
		setError(false);
	}, [email.value, password.value])
	
	
	
	const fetchData = async () => {
		if (error) return;
		
    try {
      const response = await fetch(`${apiURL}v1/auth`, {
				method: 'POST',
				body: JSON.stringify({
					email: email.value, 
					password: password.value
				})
    	});
      const json = await response.json();
      
      if (json.status === 'success') {
	      localStorage.token = json.response.token;
	      setCookie("token", json.response, {path:'/'});
	      
	      dispatch(updateToken(json.response.token));
	      dispatch(updateUsr({email: json.response.email, nombre: json.response.nombre}));
	      dispatch(loginStatus(true));
		    
	      navigate('/');
      }
      else {
	      setError(json.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const handleSubmit = e => {
		e.preventDefault();
		
		if (!email.value || !password.value)
			setError('Faltan datos');
		
		fetchData();
	}
	
	return (
		<div className="login-page">
			<div className="login-content">
				
				<div className="box box-login">
					<div className="login-title"><img src={Logo} height={60} alt='Logo' /></div>
					{
						error && 
							(
								<div className="error-msg">{error}</div>
							)
					}
					
					<form onSubmit={handleSubmit}>
						<b className="form-title">Usuario</b>
						<input type="text" name="email" value={email.value} className="form-input" onChange={email.onChange} />
						
						<b className="form-title">Contraseña</b>
						<input type="password" name="password" value={password.value} className="form-input" onChange={password.onChange} />
						
						<Button>Entrar</Button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;