import { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import apiURL from '../hooks/apiURL';

import useInput from "../hooks/useInput";
import useFile from "../hooks/useFile";

import ButtonApp from '../components/ButtonApp';
import ButtonSmall from '../components/ButtonSmall';

import Loader from '../components/Loader';


const ClientesPage = () => {
	const navigate = useNavigate();
	
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	
	const form_plataforma = useInput('');
	const form_contacto_nombre = useInput('');
	const form_contacto_email = useInput('');
	
	const form_contacto_telefono = useInput('');
	const form_franquicia = useInput(false);
	
	const form_carpeta = useInput('softuristic_');
	const form_ddbb = useInput('registr4_softuristic_');
	const form_tiene_dominio = useInput(false);
	const form_dominio = useInput('https://www.');
	const form_subdominio = useInput('');
	
	const form_admin_email = useInput('');
	const form_admin_password = useInput('');
	
	const form_demo = useInput(true);
	
	const form_logo = useFile([]);
	const form_color = useInput('#064369');
	const form_fuente = useInput('');
	
	const form_logo_url = useInput('');
	
	const form_facturacion_nombre = useInput('');
	const form_facturacion_documento = useInput('');
	const form_facturacion_direccion = useInput('');
	
	const form_facturacion_localidad = useInput('');
	const form_facturacion_cp = useInput('');
	const form_facturacion_comunidad = useInput('');
	const form_facturacion_pais = useInput('');
	
	const { id } = useParams();
	
	useEffect(() => {
		form_dominio.setValue('https://www.');
		form_subdominio.setValue('');
	}, [form_franquicia.value]);
	
	useEffect(() => {
		if (!id) {
			let n = form_plataforma.value.trim().toLowerCase();
					n = n.replaceAll(' ', '-');
			
			form_carpeta.setValue('softuristic_'+ n);
			form_ddbb.setValue('registr4_softuristic_'+ n);
			form_subdominio.setValue('https://'+ n +'.softuristic.com');
		}
	}, [form_plataforma.value]);
	
	
	const fetchDataLoad = async () => {
    try {
      const response = await fetch(`${apiURL}v1/cliente`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				},
				body: JSON.stringify({
					id: id
				})
    	});
      const json = await response.json();
      
      setLoading(false);
      
      if (json.status === 'success') {
	      form_plataforma.setValue(json.response.plataforma_nombre);
	      form_contacto_nombre.setValue(json.response.nombre);
	      form_contacto_email.setValue(json.response.email);
	      form_contacto_telefono.setValue(json.response.telefono);
	      form_franquicia.setValue(json.response.franquicia == 1 ? true : false);
	      form_carpeta.setValue(json.response.folder);
				form_ddbb.setValue(json.response.db);
				form_tiene_dominio.setValue(json.response.subdominio ? false : true);
				form_dominio.setValue(json.response.dominio);
				form_subdominio.setValue(json.response.subdominio);
				
				form_color.setValue(json.response.color);
				form_fuente.setValue(json.response.fuente);
				
				form_facturacion_nombre.setValue(json.response.factura_nombre || '');
				form_facturacion_documento.setValue(json.response.factura_documento || '');
				form_facturacion_direccion.setValue(json.response.factura_direccion || '');
				
				form_admin_email.setValue(json.response.cliente.email || '');
				
				form_facturacion_localidad.setValue(json.response.factura_localidad || '');
				form_facturacion_cp.setValue(json.response.factura_cp || '');
				form_facturacion_comunidad.setValue(json.response.factura_comunidad || '');
				form_facturacion_pais.setValue(json.response.factura_pais || '');
				
				if (json.response.logo)
					form_logo_url.setValue(`https://intranet.softuristic.com/_files/logos/${json.response.logo}`);
      }
      else {
	      
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
	  if (id) {
		  setLoading(true);
	  	fetchDataLoad(id);
	  }
	}, []);
	
	
	
	const fetchData = async () => {
    try {
	    let n = form_plataforma.value.trim().toLowerCase();
					n = n.replaceAll(' ', '-');
				
	    var data = new FormData();
					data.append('plataforma', form_plataforma.value)
					data.append('contacto_nombre', form_contacto_nombre.value)
					data.append('contacto_email', form_contacto_email.value)
					data.append('contacto_telefono', form_contacto_telefono.value)
					data.append('es_franquicia', form_franquicia.value ? 1 : 0)
					data.append('carpeta', form_carpeta.value)
					data.append('ddbb', form_ddbb.value)
					data.append('dominio', form_dominio.value)
					data.append('subdominio', n)
					data.append('admin_email', form_admin_email.value)
					data.append('admin_password', form_admin_password.value)
					data.append('demo', form_demo.value ? 1 : 0)
					data.append('logo', form_logo.value[0])
					data.append('color', form_color.value)
					data.append('fuente', form_fuente.value)
					
					data.append('factura_nombre', form_facturacion_nombre.value)
					data.append('factura_documento', form_facturacion_documento.value)
					data.append('factura_direccion', form_facturacion_direccion.value)
					data.append('factura_localidad', form_facturacion_localidad.value)
					data.append('factura_cp', form_facturacion_cp.value)
					data.append('factura_comunidad', form_facturacion_comunidad.value)
					data.append('factura_pais', form_facturacion_pais.value)
					
      const response = await fetch(`${apiURL}v1/cliente-nuevo`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				},
				body: data
    	});
    	
      const json = await response.json();
      
      if (json.status === 'success') {
	      navigate('/clientes');
      }
      else {
	      setLoading(false);
	      setError(json.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
	
	
	
	const fetchDataUpdate = async () => {
    try {
	    var data = new FormData();
					data.append('id', id)
					data.append('plataforma', form_plataforma.value)
					data.append('contacto_nombre', form_contacto_nombre.value)
					data.append('contacto_email', form_contacto_email.value)
					data.append('contacto_telefono', form_contacto_telefono.value)
					data.append('logo', form_logo.value[0])
					data.append('color', form_color.value)
					data.append('fuente', form_fuente.value)
					data.append('logo_last', form_logo_url.value)
					
					data.append('admin_password', form_admin_password.value)
					
					data.append('factura_nombre', form_facturacion_nombre.value)
					data.append('factura_documento', form_facturacion_documento.value)
					data.append('factura_direccion', form_facturacion_direccion.value)
					data.append('factura_localidad', form_facturacion_localidad.value)
					data.append('factura_cp', form_facturacion_cp.value)
					data.append('factura_comunidad', form_facturacion_comunidad.value)
					data.append('factura_pais', form_facturacion_pais.value)
					
      const response = await fetch(`${apiURL}v1/cliente-update`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				},
				body: data
    	});
    	
      const json = await response.json();
      
      if (json.status === 'success') {
	      navigate('/clientes');
      }
      else {
	      setLoading(false);
	      setError(json.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
	
	const handleSubmit = () => {
		if (!id) {
			if (
					!form_plataforma.value ||
					!form_contacto_nombre.value || 
					!form_contacto_email.value || 
					!form_contacto_telefono.value || 
					!form_carpeta.value || 
					!form_ddbb.value ||
					(!form_franquicia.value && (!form_dominio.value || !form_subdominio.value)) ||
					!form_admin_email.value ||
					!form_admin_password.value ||
					!form_color.value ||
					!form_fuente.value ||
					!form_facturacion_nombre.value ||
					!form_facturacion_documento.value ||
					!form_facturacion_direccion.value ||
					!form_facturacion_localidad.value ||
					!form_facturacion_cp.value ||
					!form_facturacion_comunidad.value ||
					!form_facturacion_pais.value
				) {
					setError('Faltan campos por completar.');
			}
			else {
				setLoading(true);
				
				fetchData()
			}
		}
		else {
			if (
					!form_plataforma.value ||
					!form_contacto_nombre.value || 
					!form_contacto_email.value || 
					!form_contacto_telefono.value || 
					!form_color.value ||
					!form_fuente.value ||
					!form_facturacion_nombre.value ||
					!form_facturacion_documento.value ||
					!form_facturacion_direccion.value ||
					!form_facturacion_localidad.value ||
					!form_facturacion_cp.value ||
					!form_facturacion_comunidad.value ||
					!form_facturacion_pais.value
				) {
					setError('Faltan campos por completar.');
			}
			else {
				setLoading(true);
				
				fetchDataUpdate()
			}
		}
	}
	
	useEffect(() => {
		setError(false);
	}, [form_plataforma.value, form_contacto_nombre.value, form_contacto_email.value, form_contacto_telefono.value, form_carpeta.value, form_ddbb.value, form_franquicia.value, form_dominio.value, form_subdominio.value, form_admin_email.value, form_admin_password.value, form_facturacion_nombre.value, form_facturacion_documento.value, form_facturacion_direccion.value, form_facturacion_localidad.value, form_facturacion_cp.value, form_facturacion_comunidad.value, form_facturacion_pais.value])
	
	
	const handleDeletePhoto = e => {
		form_logo.setValue('');
		form_logo_url.setValue('');
	}
	
	
	const fetchDataDelete = async () => {
    try {
	    var data = new FormData();
					data.append('id', id);
					
      const response = await fetch(`${apiURL}v1/cliente-delete`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				},
				body: JSON.stringify({
					id: id
				})
    	});
    	
      const json = await response.json();
      
      if (json.status === 'success') {
	      navigate('/clientes');
      }
      else {
	      setLoading(false);
	      setError(json.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
	
	useEffect(() => {
		if (form_logo.value && form_logo.value.length > 0) {
	    var src = URL.createObjectURL(form_logo.value[0]);
	    
			form_logo_url.setValue(src);
	  }
	}, [form_logo.value])
	
	
	const handleDelete = e => {
    setLoading(true);
    fetchDataDelete()
	}
	
	return (
		<>
			<div className="page-label d-flex align-items-center">
				Clientes / {id ? 'Editar / ' : ''} <div className="page-label-actual">{id ? form_plataforma.value : 'Añadir'}</div>
			</div>
			
			<div className="d-flex mb-4">
				<div>
					<NavLink to='/clientes'><ButtonSmall>Volver</ButtonSmall></NavLink>
				</div>
			</div>
			
			{
					error ? 
						(
							<div className="error-msg">{error}</div>
						)
						:
						''
				}
				
			<div className="box box-hidden">
				{
					loading ?
						(
							<div className="box-loader d-flex align-items-center justify-content-center">
								<div className="box box-shadow">
									<Loader />
								</div>
							</div>
						)
						:
						''
				}
				<div className="box-title">Añadir Plataforma</div>
				
				
				<div className="row">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Nombre Plataforma</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_plataforma.value} onChange={form_plataforma.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Nombre Contacto</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_contacto_nombre.value} onChange={form_contacto_nombre.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Email Contacto</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_contacto_email.value} onChange={form_contacto_email.onChange} />
							</div>
						</div>
					</div>
				</div>
				
				<div className="row mb-3">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Teléfono Contacto</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_contacto_telefono.value} onChange={form_contacto_telefono.onChange} />
							</div>
						</div>
					</div>
					
					{/*
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Franquicia</div>
							<label className="form-value d-flex align-items-center">
								<input type="checkbox" checked={form_franquicia.value} onChange={e => form_franquicia.setValue(!form_franquicia.value)} style={{marginRight: '6px'}} disabled={id} /> Es franquicia?
							</label>
						</div>
					</div>
					*/}
					
					<div className="col-12 col-sm-4">
					</div>
				</div>
				
				<hr />
				
				<div className="row mb-3">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Carpeta</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_carpeta.value} onChange={ form_carpeta.onChange } disabled={ true } />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">DDBB</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_ddbb.value} onChange={ form_ddbb.onChange } disabled={ true } />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						{
							//!form_franquicia.value ?
								(
									<div className="form-data">
										<div className="form-title">Dominio</div>
										<label className="form-value d-flex align-items-center">
											<input type="checkbox" checked={form_tiene_dominio.value} onChange={e => form_tiene_dominio.setValue(!form_tiene_dominio.value)} style={{marginRight: '6px'}} disabled={id} /> Tiene dominio?
										</label>
									</div>
								)
								//:
								//''
						}
					</div>
					
					<div className="col-12 col-sm-4">
						{
							//!form_franquicia.value ?
								(
									form_tiene_dominio.value ? 
										(
											<div className="form-data">
												<div className="form-title">Dominio</div>
												<div className="form-value">
													<input type="text" className="form-control" value={form_dominio.value} onChange={form_dominio.onChange} disabled={ id } />
												</div>
											</div>
										)
										:
										(
											<div className="form-data">
												<div className="form-title">Subdominio</div>
												<div className="form-value">
													<input type="text" className="form-control" value={form_subdominio.value} onChange={form_subdominio.onChange} disabled={ true } />
												</div>
											</div>
										)
								)
								//:
								//''
						}
					</div>
				</div>
				
			
				<hr />
				
				<div className="row mb-3">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Admin Email</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_admin_email.value} onChange={form_admin_email.onChange} disabled={ id } />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Admin Password</div>
							<div className="form-value">
								<input type="password" className="form-control" value={form_admin_password.value} onChange={form_admin_password.onChange} autoComplete="new-password" />
							</div>
						</div>
					</div>
					
					{
						!id && (
							<div className="col-12 col-sm-4">
								<div className="form-data">
									<div className="form-title">Demo</div>
									<label className="form-value d-flex align-items-center">
										<input type="checkbox" checked={form_demo.value} onChange={e => form_demo.setValue(!form_demo.value)} style={{marginRight: '6px'}} /> Datos demo?
									</label>
								</div>
							</div>
						)
					}
				</div>
					
				
				<hr />
				
				<div className="row mb-3">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Logo</div>
							<div className="form-value">
								<input type="file" className="form-control" onChange={form_logo.onChange} style={{overflow: 'hidden', lineHeight: '40px'}} />
							</div>
							
							{
								form_logo_url.value ?
									(
										<div className="mt-4 d-flex flex-column align-items-center">
											<img src={form_logo_url.value} width={140} />
											
											<div className="mt-2" onClick={handleDeletePhoto} style={{cursor: 'pointer', background: '#eee', fontSize: '12px', fontWeight: 700, padding: '3px 10px', borderRadius: '3px'}}>Quitar</div>
										</div>
									)
									:
									''
							}
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Color</div>
							<div className="form-value">
								<input type="color" className="form-control" value={form_color.value} onChange={form_color.onChange} autoComplete="new-password" style={{padding: 0, overflow: 'hidden'}} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Fuente</div>
							<label className="form-value d-flex align-items-center">
								<select className="form-control" onChange={form_fuente.onChange} value={form_fuente.value}>
									<option value=""></option>
									<option value="IBM Plex Sans">IBM Plex Sans</option>
									<option value='Gilroy'>Gilroy</option>
								</select>
							</label>
						</div>
					</div>
				</div>
				
				
				<hr />
				
				
				<div className="row mb-3">
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Facturación Nombre</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_nombre.value} onChange={form_facturacion_nombre.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Facturación Documento</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_documento.value} onChange={form_facturacion_documento.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-4">
						<div className="form-data">
							<div className="form-title">Facturación Dirección</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_direccion.value} onChange={form_facturacion_direccion.onChange} />
							</div>
						</div>
					</div>
				</div>
				
				<div className="row mb-3">
					<div className="col-12 col-sm-3">
						<div className="form-data">
							<div className="form-title">Facturación Localidad</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_localidad.value} onChange={form_facturacion_localidad.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-3">
						<div className="form-data">
							<div className="form-title">Facturación CP</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_cp.value} onChange={form_facturacion_cp.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-3">
						<div className="form-data">
							<div className="form-title">Facturación Comunidad</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_comunidad.value} onChange={form_facturacion_comunidad.onChange} />
							</div>
						</div>
					</div>
					
					<div className="col-12 col-sm-3">
						<div className="form-data">
							<div className="form-title">Facturación País</div>
							<div className="form-value">
								<input type="text" className="form-control" value={form_facturacion_pais.value} onChange={form_facturacion_pais.onChange} />
							</div>
						</div>
					</div>
				</div>
				
				
				
				<div className="d-flex justify-content-end">
					<div className="d-flex">
						{
							id ? 
								(<ButtonApp onClick={handleDelete} color="red">Eliminar</ButtonApp>)
								:
								''
						}
						<ButtonApp onClick={handleSubmit}>Guardar</ButtonApp>
					</div>
				</div>
			</div>
			
			<div className="d-flex">
				<div>
					<NavLink to='/clientes'><ButtonSmall>Volver</ButtonSmall></NavLink>
				</div>
			</div>
		</>
	);
}

export default ClientesPage;