//import ReactDOM from 'react-dom';
import { render } from 'react-dom';

import { store } from './app/store';
import { Provider } from 'react-redux';

import App from './App';

//ReactDOM.render(<App />, document.getElementById('root'));
render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);