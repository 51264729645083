import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		
		example: (state, action) => {
		}
		
	}
});

export const { example } = dashboardSlice.actions;
export default dashboardSlice.reducer;