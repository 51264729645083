import { useSelector, useDispatch } from 'react-redux';

import { toggleNotifications } from '../features/layout/layoutSlice';

const Navbar = () => {
	const dispatch = useDispatch();
	
	const auth = useSelector(state => state.auth);
	const layout = useSelector(state => state.layout);
	
	const handleToggle = () => {
		dispatch(toggleNotifications());
	}
	
	return (
		<div className="top-bar d-flex align-items-center">
			<div className="part">
				<div className="title">Panel administrador</div>
				<div className="subtitle">gestion de cuentas de Softurismo</div>
			</div>
			
			<div className="flex-grow-1 d-flex align-items-center justify-content-end">
				<div className="alerts d-flex align-items-center">
					<span className="material-icons outlined" onClick={handleToggle}>{ layout.notifications ? 'notifications_active' : 'notifications' }</span>
				</div>
				
				<div className="profile d-flex align-items-center">
					<div className="profile-img" />
					
					<div className="profile-data">
						<div className="title">{auth.usr.nombre}</div>
						<div className="subtitle">Administrador</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;