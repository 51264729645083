const ButtonApp = ({ children, onClick, color }) => {
	color = color || '';
	
	return (
		<button className={`button-app ${color}`} onClick={onClick}>
			{children}
		</button>
	);
}

export default ButtonApp;