import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";

import { updateToken, loginStatus, updateUsr } from './features/auth/authSlice';


import apiURL from './hooks/apiURL';

//

import './styles/Login.scss';

import './styles/Fonts.scss';

import './styles/App.scss';
import './styles/LeftMenu.scss';
import './styles/Navbar.scss';

import './styles/NotFound.scss';

import './styles/Button.scss';

import './styles/ButtonApp.scss';

import './styles/ButtonSmall.scss';

import './styles/Loader.scss';

import './styles/Acordeon.scss';

//

import LoginPage from './pages/LoginPage';

import DashboardAdminPage from './pages/DashboardPage';
import ClientesPage from './pages/ClientesPage';
import ClientesAddPage from './pages/ClientesAddPage';
import ClientesViewPage from './pages/ClientesViewPage';


import NotFoundPage from './pages/NotFoundPage';

//

import LeftMenu from './components/LeftMenu';
import Navbar from './components/Navbar';

//

const App = (a) => {
	const auth = useSelector(state => state.auth);
	
	const dispatch = useDispatch();
	
	const [cookies, setCookie] = useCookies();
	
	const fetchData = async (token) => {
		dispatch(loginStatus(true));
    try {
      const response = await fetch(`${apiURL}v1/authToken`, {
				method: 'POST',
				body: JSON.stringify({
					token: token
				})
    	});
      const json = await response.json();
      
      if (json.status === 'success') {
	      localStorage.token = token;
	      setCookie("token", token, {path:'/'});
	      
	      dispatch(updateToken(token));
	      dispatch(updateUsr({email: json.response.email, nombre: json.response.nombre}));
	      dispatch(loginStatus(true));
      }
      else {
	      dispatch(loginStatus(false));
      }
    } catch (error) {
      console.log("error", error);
      dispatch(loginStatus(false));
    }
  };
  
	useEffect(() => {
	  if (localStorage.token) {
		  fetchData(localStorage.token);
	  }
	}, []);
	
	
	return (
		<BrowserRouter>
			{
				!auth.login ? 
					(
						<Routes>
							<Route path='*' element={<LoginPage />} />
						</Routes>
					)
					:
					(
						<div className="app-root">
							<LeftMenu />
							
							<div className="app-container">
								<Navbar />
								
								<div className="app-container-sections">
									<Routes>
										<Route path='/' element={<DashboardAdminPage />} />
										
										<Route path='/clientes' element={<ClientesPage />} />
										
										<Route path='/clientes/crear' element={<ClientesAddPage />} />
										<Route path='/clientes/editar/:id' element={<ClientesAddPage />} />
										<Route path='/clientes/ver/:id' element={<ClientesViewPage />} />
										
										<Route path='*' element={<NotFoundPage />} />
									</Routes>
								</div>
							</div>
						</div>
					)
			}
		</BrowserRouter>
	);
}

export default App;