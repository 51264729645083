import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import apiURL from '../hooks/apiURL';

import { NavLink, Link } from 'react-router-dom';

import ButtonSmall from '../components/ButtonSmall';
import ButtonApp from '../components/ButtonApp';

import Loader from '../components/Loader';

const ClientesPage = () => {
	const [clientes, setClientes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [search, setNewSearch] = useState("");
	
	const fetchData = async () => {
    try {
      const response = await fetch(`${apiURL}v1/clientes`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
    	});
      const json = await response.json();
      
      setLoading(false);
      
      if (json.status === 'success') {
	      setClientes(json.response);
      }
      else {
	      
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
	  fetchData();
	}, []);
	
	const handleSearchChange = (e) => {
	  setNewSearch(e.target.value);
	};
	
	const filtered = !search
    ? clientes
    : clientes.filter((cliente) =>
        cliente.id.toLowerCase().includes(search.toLowerCase()) ||
        cliente.email.toLowerCase().includes(search.toLowerCase()) ||
        cliente.plataforma_nombre.toLowerCase().includes(search.toLowerCase()) ||
        cliente.telefono.toLowerCase().replaceAll(' ', '').includes(search.toLowerCase().replaceAll(' ', '')) ||
        (search.toLowerCase() == 'franquicia' && cliente.franquicia == 1)
      );
	
	return (
		<>
			<div className="page-label d-flex align-items-start">
				Clientes
				
				<div className="flex-grow-1 d-flex justify-content-end">
					<div>
						<NavLink to='/clientes/crear'><ButtonApp>Añadir cliente</ButtonApp></NavLink>
					</div>
				</div>
			</div>
			
			
			<div className="form-data">
				<div className="form-title">Buscar</div>
				<div className="form-value">
					<input type="text" className="form-control" value={search} onChange={handleSearchChange}/>
				</div>
			</div>
			
			<div className="box graph box-table">
				<table border={0} cellPadding={0} cellSpacing={0} width='100%'>
					<thead>
						<tr>
							<td align="center" width={55}><b>#</b></td>
							<td width={150}>Plataforma</td>
							<td>Contacto</td>
							<td align="center" width={75}>Admins</td>
							<td align="center" width={75}>Clie.</td>
							<td align="center" width={105}>Limp.</td>
							<td align="center" width={95}>Inmu.</td>
							<td align="center" width={95}>Hues.</td>
							<td align="center" width={95}>Mens.</td>
							
							<td align="center" width={90}>Franquicia</td>
							<td align="center" width={90}>Subscripción</td>
							<td align="center" width={50}>Web</td>
							<td align="center" width={120}>Acciones</td>
						</tr>
					</thead>
					
					<tbody>
						{
							loading ? 
								(
									<tr>
										<td colSpan={11} style={{padding: '16px 0'}}><Loader /></td>
									</tr>
								)
								:
								!filtered.length ? 
									(
										<tr>
											<td colSpan={11} style={{padding: '16px 0'}}>
												<div className="d-flex justify-content-center">
													<div className="empty">Sin Datos</div>
												</div>
											</td>
										</tr>
									)
									:
									(
										filtered && filtered.map(item => {
											let url = item.dominio;
											if (item.subdominio)
												url = `https://${item.subdominio}.softuristic.com`;
											
											return (
												<tr key={item.id}>
													<td align="center"><b>{item.id}</b></td>
													<td>{item.plataforma_nombre}</td>
													<td>
														<div className="table-first-line">{item.email}</div>
														<div className="table-last-line">{item.telefono}</div>
													</td>
													<td align="center"><div className="table-number">{item.admins}</div></td>
													<td align="center"><div className="table-number">{item.clientes}</div></td>
													<td align="center"><div className="table-number">{item.limpiadores}</div></td>
													
													<td align="center"><div className="table-number">{item.inmuebles}</div></td>
													<td align="center"><div className="table-number">{item.huespedes}</div></td>
													<td align="center"><div className="table-number">{item.mensajes}</div></td>
													
													<td>
														<div className="d-flex justify-content-center">
															{
																item.franquicia == 1 ?
																	(<div className="color-mark green d-flex align-items-center">Si</div>)
																	:
																	(<div className="color-mark red d-flex align-items-center">No</div>)
															}
														</div>
													</td>
													
													<td>
														<div className="d-flex justify-content-center">
															{
																item.subscripcion == 1 ?
																	(<div className="color-mark green d-flex align-items-center">Si</div>)
																	:
																	(<div className="color-mark red d-flex align-items-center">No</div>)
															}
														</div>
													</td>
													
													<td align="center"><a href={url} target="_blank"><ButtonSmall color='black'>Entrar</ButtonSmall></a></td>
													
													<td>
														<div className="d-flex justify-content-center">
															<NavLink to={`/clientes/editar/${item.id}`}><ButtonSmall color='blue'>Editar</ButtonSmall></NavLink>
															{/* <NavLink to={`/clientes/ver/${item.id}`} style={{marginLeft: '4px'}}><ButtonSmall color='blue'>Ver</ButtonSmall></NavLink> */}
														</div>
													</td>
												</tr>
											)
										})
									)
						}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default ClientesPage;