const DashboardPage = () => {
	
	return (
		<>
			<div className="page-label d-flex align-items-center">
				Dashboard
			</div>
			
			
			123
		</>
	);
}

export default DashboardPage;