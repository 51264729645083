const Loader = ({ size, text }) => {
	size = size || 40;
	
	return (
		<div className="d-flex flex-column align-items-center loader">
			<svg className="svg" viewBox="0 0 50 50" style={{width: size+'px', height: size+'px'}}>
				<circle className="path" cx={25} cy={25} r={20} fill="none" strokeWidth={5}></circle>
			</svg>
			
			{
				text && (
					<div className="text">Cargando</div>
				)
			}
		</div>
	);
}

export default Loader;