import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<div className="error-page w-100 h-100 d-flex align-items-center justify-content-center">
			<div>
				<div className="d-flex">
					<div className="error-code">404</div>
					
					<div style={{marginLeft: '48px'}}>
						<div className="title">SORRY!</div>
						
						<div className="subtitle">The page you’re looking for was not found.</div>
					</div>
				</div>
				
				<div className="text-center mt-5">
					<NavLink to={-1} className="link">back to home</NavLink>
				</div>
			</div>
		</div>
	);
}

export default NotFoundPage;