import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";

import { unlogin } from '../features/auth/authSlice';

import Logo from '../media/LOGO_Softuristic_b.png';



const LeftMenu = () => {
	const layout = useSelector(state => state.layout);
	
	const dispatch = useDispatch();
	
	const navigate = useNavigate();
	
	const [cookies, setCookie, removeCookie] = useCookies();
	
	const handleSalir = () => {
		localStorage.clear();
		removeCookie('token', {path:'/'})
		dispatch(unlogin());
		navigate('/');
	}
	
	return (
		<div className={`left-menu ${layout.menu ? 'd-flex' : 'd-none'} flex-column`}>
			<NavLink to='/' className="logo-hook d-flex align-items-center justify-content-center">
				<img src={Logo} alt='logo' height={40} />
			</NavLink>
			
			<div className="inside flex-grow-1 d-flex flex-column">
				<div className="menu">
					
					<NavLink to='/' end className="item d-flex align-items-center">Dashboard</NavLink>
					<NavLink to='/clientes' className="item d-flex align-items-center">Clientes</NavLink>
					
					<div className="item d-flex align-items-center" onClick={handleSalir}>Salir</div>
				</div>
				
				<div className="help flex-grow-1 d-flex align-items-end justify-content-center">
					<div>
						<small>
							<b>ThePiso Intranet</b>
						</small>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeftMenu;