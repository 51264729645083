import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import apiURL from '../hooks/apiURL';

import { NavLink } from 'react-router-dom';

import ButtonSmall from '../components/ButtonSmall';
import ButtonApp from '../components/ButtonApp';

import Loader from '../components/Loader';


const ClientesViewPage = () => {
	const [cliente, setCliente] = useState({});
	const [loading, setLoading] = useState(true);
	
	const { id } = useParams();
	
	const fetchData = async () => {
    try {
      const response = await fetch(`${apiURL}v1/cliente`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				},
				body: JSON.stringify({
					id: id
				})
    	});
      const json = await response.json();
      
      setLoading(false);
      
      if (json.status === 'success') {
	      setCliente(json.response);
      }
      else {
	      
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
	  fetchData();
	}, []);
	
	return (
		<>
			<div className="page-label d-flex align-items-center">
				Clientes / Ver / <div className="page-label-actual">{cliente.plataforma_nombre}</div>
			</div>
			
			<div className="d-flex mb-4">
				<div>
					<NavLink to='/clientes'><ButtonSmall>Volver</ButtonSmall></NavLink>
				</div>
			</div>
			
			<div className="box">
				{
					loading ? 
						(<Loader />)
						:
						(
							<div>
								<div className="box-title">Plataforma {cliente.plataforma_nombre}</div>
							</div>
						)
				}
			</div>
			
			<div className="d-flex">
				<div>
					<NavLink to='/clientes'><ButtonSmall>Volver</ButtonSmall></NavLink>
				</div>
			</div>
		</>
	);
}

export default ClientesViewPage;