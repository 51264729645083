import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	menu: true,
	notifications: false
};

export const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		
		toggleMenu: (state, action) => {
			state.menu = !state.menu;
		},
		
		toggleNotifications: (state, action) => {
			if (action.payload != undefined)
				state.notifications = action.payload;
			else
				state.notifications = !state.notifications;
		}
		
	}
});

export const { toggleMenu, toggleNotifications } = layoutSlice.actions;
export default layoutSlice.reducer;